import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const CompanyMain = styled.div`
  background: linear-gradient(
      180deg,
      #fff 36.58%,
      rgba(205, 233, 240, 0.5) 100%
    ),
    #fff;
  @media (max-width: 480px) {
    display: none;
  }

  h2 {
  }
  .line {
    width: 100px;
    height: 1px;
    background: #000;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin: 150px 0;
  }
  .aboutMain {
    position: relative;
    img {
      width: 100%;
    }
    h2 {
      position: absolute;
      top: 0;
      z-index: 10;
      color: #fff;
      font-family: Pretendard;
      font-size: 64px;
      top: 46%;
      left: 19%;
    }
  }

  .tabList {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 20px 0;
    margin-top: 80px;
    text-align: center;
    justify-content: center;
    li {
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: border-bottom 0.3s;
      margin-bottom: 150px;
      font-family: Pretendard;
      font-size: 30px;
      &.active {
        border-bottom: 3px solid #00b3ba;
        font-weight: bold;
        color: #00b3ba;
      }
    }
  }

  .tabContent {
    padding: 20px;
    padding-bottom: 100px;
    .aboutGemedicare,
    .greeting {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #333d4b;
      font-family: Pretendard;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
    }

    .aboutGemedicare {
      display: block;
      > p:nth-of-type(1) {
        font-size: 2rem;
        width: 62%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        line-height: 58px;
        @media (max-width: 1919px) {
          font-size: 2rem;
          width: 70%;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          line-height: 58px;
        }
      }
    }

    &.greeting {
      display: block;
    }
    .greeting {
      margin-bottom: 320px;
      padding: 0 10%;
      > p {
        color: #333d4b;
        font-family: Pretendard;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        width: 100%;

        .mint {
          color: #00b3ba;
        }
      }
      > p:nth-of-type(2) {
      }
      > p:nth-of-type(3) {
        color: #333d4b;
        font-family: Pretendard;
        font-size: 36px;
        font-weight: 400;
        text-align: right;
        font-weight: 700;
      }
      .greetingText {
        color: #333d4b;
        font-family: Pretendard;
        font-size: 34px;
        font-weight: 400;
        margin: 40px 0;
        line-height: 58px;
        white-space: nowrap;
      }
    }
    .promise {
      > p {
        color: #00b3ba;
        font-family: Pretendard;
        font-size: 40px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 80px;
      }

      ul {
        display: flex;
        justify-content: center;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 450px;
          height: 400px;
          border-radius: 50px;
          border: 5px solid #00b3ba;
          background: #fff;
          text-align: center;
          margin-right: 30px;
          p {
            font-size: 28px;
            line-height: 40px;
            white-space: nowrap;
          }
        }
      }
    }
  }
`;

const MobileCompanyMain = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
    background: linear-gradient(
      180deg,
      #fff 36.58%,
      rgba(205, 233, 240, 0.5) 100%
    ),
    #fff;
    .line {
      width: 100px;
      height: 1px;
      background: #000;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 150px 0;
    }
    .aboutMain {
      position: relative;
      img {
        width: 100%;
      }
      h2 {
        position: absolute;
        top: 0;
        z-index: 10;
        color: #fff;
        font-family: Pretendard;
        font-size: 64px;
        top: 46%;
        left: 19%;
      }
    }

    .tabList {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 20px 0;
      margin-top: 80px;
      text-align: center;
      justify-content: center;
      li {
        padding: 10px 20px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: border-bottom 0.3s;
        font-family: Pretendard;
        font-size: 30px;
        &.active {
          border-bottom: 3px solid #00b3ba;
          font-weight: bold;
          color: #00b3ba;
        }
      }
    }

    .tabContent {
      padding: 100px 0 200px 0;
      height: auto; /* 높이를 자동으로 조정 */
      min-height: 100vh; /* 페이지가 스크롤 될 수 있도록 최소 높이를 설정 */
      overflow-y: auto; /* 필요할 때만 세로 스크롤 생성 */
      .aboutGemedicare,
      .greeting {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #333d4b;
        font-family: Pretendard;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
      }

      .aboutGemedicare {
        display: block;
        > p:nth-of-type(1) {
          font-size: 28px;
          width: 80%;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          line-height: 56px;
        }
      }

      &.greeting {
        display: block;
      }
      .greeting {
       
        padding: 0 8%;
        > p {
          color: #333d4b;
          font-family: Pretendard;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          width: 100%;
          line-height: 46px;
          .mint {
            color: #00b3ba;
          }
        }
        > p:nth-of-type(2) {
        }
        > p:nth-of-type(3) {
          color: #333d4b;
          font-family: Pretendard;
          font-size: 26px;
          font-weight: 400;
          text-align: right;
          font-weight: 700;
          line-height: 38px;
        }
        .greetingText {
          color: #333d4b;
          font-family: Pretendard;
          font-size: 24px;
          font-weight: 400;
          line-height: 48px;
          word-wrap: break-word;
        word-break: break-all;
        }
      }
      .promise {
        > p {
          color: #00b3ba;
          font-family: Pretendard;
          font-size: 40px;
          font-weight: 700;
          text-align: center;
          margin-bottom: 80px;
          width: 70%;
          text-align: center;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          line-height: 60px;
        }

        ul {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 230px;
            border-radius: 30px;
            border: 3px solid #00b3ba;
            background: #fff;
            text-align: center;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 30px;
            p {
              font-size: 22px;
              line-height: 40px;
              white-space: nowrap;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;

const About = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = parseInt(queryParams.get("tab"), 10) || 0;
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);
  useEffect(() => {
    window.scrollTo(0, 0); // 탭 전환 시 상단으로 스크롤 이동
    const tabContentElement = document.querySelector('.tabContent');
    
    if (tabContentElement) {
      tabContentElement.style.minHeight = "100vh"; // 기본적으로 최소 높이를 설정
    }
  }, [activeTab]);

  return (
    <>
      <CompanyMain>
        <div className="aboutMain">
          <img src="/asset/Frame 86639.png" alt="About Us" />
          <h2>회사소개</h2>
        </div>
        <ul className="tabList">
          <li
            className={activeTab === 0 ? "active" : ""}
            onClick={() => handleTabClick(0)}
          >
            가은메디케어
          </li>
          <li
            className={activeTab === 1 ? "active" : ""}
            onClick={() => handleTabClick(1)}
          >
            인사말
          </li>
        </ul>
        <div key={`tab-${activeTab}`}  className="tabContent">
          {activeTab === 0 && (
            <div className="aboutGemedicare">
              <p>
                가은메디케어는 건강보험공단 정식 등록 업체로서, 가정용
                산소발생기와 가정용 인공호흡기 임대 전문업체입니다. 호흡기
                질환을 가진 환자들이 안전하고 편리하게 치료와 삶의 질 향상을
                위해 끊임 없이 노력할 것을 약속 드립니다.
              </p>
              <div className="line"></div>
              <div className="promise">
                <p>가은메디케어의 3대 약속</p>
                <ul>
                  <li>
                    <p>고객만족을 최우선으로 한다.</p>
                  </li>
                  <li>
                    <p>
                      철저한 제품 관리를 통해
                      <br /> 환자의 건강증진을 위해 노력한다.
                    </p>
                  </li>
                  <li>
                    <p>
                      신뢰와 정직을 통해
                      <br /> 고객과 함께 성장한다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="greeting">
              <p>
                가은메디케어는 <span className="mint">신뢰</span>와{" "}
                <span className="mint">정직</span>을 약속드립니다.
              </p>
              <p className="greetingText">
                저희 가은메디케어는 홈케어 서비스를 제공하는 기업으로서
                <br />
                가정용 산소발생기 임대 서비스와, 가정용 인공호흡기 임대서비스를
                제공하고 있으며
                <br /> 전 구성원이 환자를 내 가족이라는 생각으로 책임감 있고
                진실한 마음으로
                <br /> 최선을 다하는 기업이 되도록 노력하겠습니다 감사합니다.
              </p>
              <p>가은메디케어 임직원 일동</p>
            </div>
          )}
        </div>
      </CompanyMain>
      <MobileCompanyMain>
        <div className="aboutMain">
          <img src="/asset/mob_about_main.png" alt="AboutUs" />
        </div>
        <ul className="tabList">
          <li
            className={activeTab === 0 ? "active" : ""}
            onClick={() => handleTabClick(0)}
          >
            가은메디케어
          </li>
          <li
            className={activeTab === 1 ? "active" : ""}
            onClick={() => handleTabClick(1)}
          >
            인사말
          </li>
        </ul>
        <div key={`tab-${activeTab}`} className="tabContent">
          {activeTab === 0 && (
            <div className="aboutGemedicare">
              <p>
                가은메디케어는 건강보험공단 정식 등록 업체로서, 가정용
                산소발생기와 가정용 인공호흡기 임대 전문업체입니다. 호흡기
                질환을 가진 환자들이 안전하고 편리하게 치료와 삶의 질 향상을
                위해 끊임 없이 노력할 것을 약속 드립니다.
              </p>
              <div className="line"></div>
              <div className="promise">
                <p>가은메디케어의 3대 약속</p>
                <ul>
                  <li>
                    <p>
                      고객만족을 <br />
                      최우선으로 한다.
                    </p>
                  </li>
                  <li>
                    <p>
                      철저한 제품 관리를
                      <br /> 통해 환자의 건강 증진을 <br />
                      위해 노력한다.
                    </p>
                  </li>
                  <li>
                    <p>
                      신뢰와 정직을 통해 <br />
                      고객과 함께 성장한다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="greeting">
              <p>
                가은메디케어는<br/> <span className="mint">신뢰</span>와{" "}
                <span className="mint">정직</span>을 약속드립니다.
              </p>
              <p className="greetingText">
                저희 가은메디케어는 홈케어 서비스를 제공하는 기업으로서
               
                가정용 산소발생기 임대 서비스와, 가정용 인공호흡기 임대서비스를
                제공하고 있으며
                 전 구성원이 환자를 내 가족이라는 생각으로 책임감 있고
                진실한 마음으로
                최선을 다하는 기업이 되도록 노력하겠습니다 감사합니다.
              </p>
              <p>가은메디케어<br/> 임직원 일동</p>
            </div>
          )}
        </div>
      </MobileCompanyMain>
    </>
  );
};

export default About;
